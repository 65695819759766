import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import ImageViewer from "../../../sampoorna-siksha/components/reviewSteps/ImageViewer";
import moment from "moment";
import { CRED_PERCENT } from "../../../mastertables/utils/const";
import {
  calculateTotalMarksByAttempt,
  calculateTotalMaxCredits,
  calculateTotalMaxMarks,
  getMarksByAttemptAndSub,
  getResultClassByAttemptAndSub,
  getStatusClassByAttemptAndSub,
} from "../../../management/utils/utils";
import { Button, Empty } from "antd";
import { APRV, DEN } from "../../utils/const";

const MarksApproval = (props) => {
  const generateMarksTable = () => {
    const { marks } = props.tasks.currentTask;
    const entity = props.tasks.currentTask.entity[0];

    if (!marks || marks.length === 0) {
      return "";
    }
    const studentSemId = marks[0].studentSemId;

    let currentSem = null;

    for (const sem of entity.semisters) {
      if (sem.studentSemId === studentSemId) {
        currentSem = sem;
      }
    }
    const creditSystem = entity.institute?.affiliation.credSystem;
    const year = moment(entity.startYear).get("year");

    const courseCur = entity.course.curriculum
      ?.filter((cur) => cur.effectiveYear <= year)
      .sort((a, b) => b.effectiveYear - a.effectiveYear);

    const courseSubj = courseCur[0]?.curriculumSubjects?.filter(
      (cs) => cs.semId === currentSem.courseSemId
    );
    const html = [];
    const header = [
      <TableCell>Year / Semester</TableCell>,
      <TableCell>Attempt</TableCell>,
    ];

    const allRows = [];
    const rows = [<TableCell></TableCell>, <TableCell>Max Marks</TableCell>];

    if (courseSubj && courseSubj.length > 0) {
      for (const cs of courseSubj) {
        header.push(<TableCell>{cs.subject.name}</TableCell>);
        rows.push(
          <TableCell>
            {creditSystem === CRED_PERCENT && (cs.maxMarks || 0)}
            {creditSystem !== CRED_PERCENT && (cs.credits || 0)}
          </TableCell>
        );
      }
      if (creditSystem === CRED_PERCENT) {
        header.push(<TableCell>Total</TableCell>);
      } else {
        header.push(<TableCell>SGPA</TableCell>);
      }
      rows.push(
        <TableCell>
          {creditSystem === CRED_PERCENT && calculateTotalMaxMarks(courseSubj)}
          {/* {creditSystem !== CRED_PERCENT &&
            calculateTotalMaxCredits(courseSubj)} */}
        </TableCell>,
        <TableCell></TableCell>
      );
      allRows.push(<TableRow>{rows}</TableRow>);

      const attempt = marks[0].attempt;
      const markRows = [
        <TableCell>{currentSem.semDetails.semName}</TableCell>,
        <TableCell>Attempt - {attempt}</TableCell>,
      ];
      for (const cs of courseSubj) {
        markRows.push(
          <TableCell
            className={getStatusClassByAttemptAndSub(
              currentSem.studentMarks,
              attempt,
              courseSubj
            )}
          >
            {getMarksByAttemptAndSub(
              currentSem.studentMarks,
              attempt,
              cs.curSubId
            ) || "-"}
          </TableCell>
        );
      }
      markRows.push(
        <TableCell
          className={getStatusClassByAttemptAndSub(
            currentSem.studentMarks,
            attempt,
            courseSubj
          ) +
          " " +
          getResultClassByAttemptAndSub(
            currentSem.studentMarks,
            attempt,
            courseSubj
          )}
        >
          {calculateTotalMarksByAttempt(
            currentSem.studentMarks,
            courseSubj,
            attempt
          )}
        </TableCell>,
        <TableCell></TableCell>
      );
      allRows.push(<TableRow>{markRows}</TableRow>);
      html.push(
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>{header}</TableRow>
            </TableHead>
            <TableBody>{allRows}</TableBody>
          </Table>
        </TableContainer>
      );
    }
    return html;
  };

  return (
    <div className="marks-approval-container">
      {props.tasks.currentTask.marks?.length === 0 &&
        <Empty description={<span>Request is deleted by Staff/Student.</span>} />
      }
      {props.tasks.currentTask.entity !== undefined && (
        <>
          <h2>{props.tasks.currentTask.entity[0].course.courseName}</h2>
          <div style={{ marginBottom: "10px" }}>{generateMarksTable()}</div>
          <ImageViewer
            imageList={props.tasks.currentTask.uploads}
            showActions={false}
            loading={props.tasks.loading}
            year={props.tasks.currentTask.entity[0].studentProfile.batch}
            {...props}
          />
        </>
      )}
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        {
          (props.tasks.currentTask.marks?.length > 0 && props.currentTask.status === 3 && [(
            <Button
              type="primary"
              onClick={(e) =>
                props.changeStatus(
                  props.currentTask.taskId,
                  APRV,
                  props.currentTask
                )
              }
              style={{ marginRight: 8 }}
            >
              Approve
            </Button>
          ),
          (
            <Button
              onClick={(e) =>
                props.changeStatus(
                  props.currentTask.taskId,
                  DEN,
                  props.currentTask
                )
              }
              style={{ marginRight: 8 }}
            >
              Deny
            </Button>
          )])
        }

        <Button onClick={(e) => props.onClose(e)} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default MarksApproval;
