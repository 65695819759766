import React from "react";
import {
  Card,
  DatePicker,
  Form,
  Button,
  notification,
  Tabs,
  Input,
  Switch,
  Spin,
} from "antd";
import moment from "moment";
import "../styles/admin.css";
import UsersListContainer from "../containers/UsersListContainer";
import DocumentsUploadContainer from "../../sampoorna-siksha/containers/DocumentsUploadContainer";
import SMSTemplatesContainer from "../containers/SMSTemplatesContainer";
import SendSMSContainer from "../containers/SendSMSContainer";
import HallTicketText from "./HallTicketText";
import { ADMIN } from "../../base/utils/roles";
import { CleanUp } from "./CleanUp";

const dateFormat = "DD/MM/YYYY";
const dateTimeFormat = "DD/MM/YYYY hh:mm A";
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

class Configure extends React.Component {
  state = {
    activeKey: "sysConfig",
  };

  componentWillMount = () => {
    this.props.getSystemConfig();
  };

 onFinish = (form) => {
    // Validate video ID
    if (!form.videoId && form.videoStatus) {
      notification.error({
        message: "Error",
        description: "Please enter a Video ID.",
      });
      return; // Stop further processing if validation fails
    }

    // Rest of the code
    this.props.saveSystemConfig(form);
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current.year() < moment().year();
  };

  showNotifications = () => {
    let message = "";
    // if (this.props.student.successMessage) {
    //     message += this.props.student.successMessage;
    // }

    if (this.props.appConfig.successMessage) {
      message += this.props.appConfig.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      this.props.resetMessage();
    }
  };

  getFieldData = () => {
    const fieldData = [];
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      let label = "";
      if (config.configCd === "APP_START_DT") {
        label = "applicationStartDate";
        fieldData.push({
          name: [label],
          value: moment(config.configVal),
        });
      } else if (config.configCd === "APP_END_DT") {
        label = "applicationEndDate";
        fieldData.push({
          name: [label],
          value: moment(config.configVal),
        });
      } else if (config.configCd === "EXAM_DT") {
        label = "examDate";
        fieldData.push({
          name: [label],
          value: moment(config.configVal),
        });
      } else if (config.configCd === "SCROLL") {
        label = "scrolling";
        fieldData.push({
          name: [label],
          value: config.info ? config.info : config.configVal,
        });
      } else if (config.configCd === "SCROLL_ST") {
        label = "scrollStatus";
        fieldData.push({
          name: [label],
          value: config.configVal === "Y",
        });
      } else if (config.configCd === "REQ_DOCS") {
        label = "requiredDocs";
        fieldData.push({
          name: [label],
          value: config.info,
        });
      } else if (config.configCd === "VIDEO_ST") {
        label = "videoStatus";
        fieldData.push({
          name: [label],
          value: config.configVal === "Y",
        });
      } else if (config.configCd === "VIDEO_ID") {
        label = "videoId";
        fieldData.push({
          name: [label],
          value: config.info ? config.info : config.configVal,
        });
      }
    }

    return fieldData;
  };

  callback = (key) => {
    // console.log(key);
    // this.setState({activeKey: key});
    this.props.history.push(`/config/${key}`);
  };

  render() {
    const fieldData = this.getFieldData();
    const activeKey = this.props.match.params.key;
    return (
      <div id="admin-config-container">
        <Card>
          <Tabs onTabClick={(e) => this.callback(e)} activeKey={activeKey}>
            <TabPane tab="System Configuration" key="sysConfig">
              <div id="sys-config-container">
                <Spin spinning={this.props.appConfig.loading}>
                  <Form fields={fieldData} onFinish={this.onFinish}>
                    <Form.Item
                      {...formItemLayout}
                      name="applicationStartDate"
                      label="Application Start Date :"
                      rules={[
                        {
                          required: true,
                          message: "Please select Application Start Date.",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        className="full-width"
                        showToday={false}
                        disabledDate={this.disabledDate}
                      />
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        // prevValues.applicationEndDate !== currentValues.applicationEndDate ||
                        prevValues.applicationStartDate !==
                        currentValues.applicationStartDate
                      }
                    >
                      {({ getFieldValue }) => {
                        const startDate = getFieldValue([
                          "applicationStartDate",
                        ]);

                        return (
                          <Form.Item
                            {...formItemLayout}
                            name="applicationEndDate"
                            label="Application End Date :"
                            rules={[
                              {
                                required: true,
                                message: "Please select Application End Date.",
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    value &&
                                    getFieldValue("applicationStartDate") >
                                      value
                                  ) {
                                    return Promise.reject(
                                      "Application End Date is not valid."
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              format={dateFormat}
                              className="full-width"
                              showToday={false}
                              disabledDate={(current) => current < startDate}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.applicationEndDate !==
                        currentValues.applicationEndDate
                      }
                    >
                      {({ getFieldValue }) => {
                        const endDate = getFieldValue(["applicationEndDate"]);

                        return (
                          <Form.Item
                            {...formItemLayout}
                            name="examDate"
                            label="Exam Date :"
                            rules={[
                              {
                                required: true,
                                message: "Please select Exam date.",
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    value &&
                                    getFieldValue("applicationEndDate") > value
                                  ) {
                                    return Promise.reject(
                                      "Exam Date is not valid."
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              showTime
                              format={dateTimeFormat}
                              className="full-width"
                              showToday={false}
                              disabledDate={(current) => current < endDate}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      {...formItemLayout}
                      name="requiredDocs"
                      label="Required Documents Message :"
                      hidden={true}
                    >
                      <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                      {...formItemLayout}
                      name="scrolling"
                      label="Scrolling :"
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                      name="scrollStatus"
                      {...formItemLayout}
                      label="Scrolling Status"
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="On" unCheckedChildren="Off" />
                    </Form.Item>
                    <Form.Item
                      name="videoStatus"
                      {...formItemLayout}
                      label="Show Video"
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="On" unCheckedChildren="Off" />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      name="videoId"
                      label="Video Id :"
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button mg-l"
                      style={{ margin: "auto", display: "block" }}
                    >
                      Save
                    </Button>
                  </Form>
                </Spin>
              </div>
            </TabPane>
            <TabPane tab="Hallticket Text" key="htckt">
              <HallTicketText {...this.props} />
            </TabPane>
            <TabPane tab="Associate's List" key="assoc">
              <UsersListContainer></UsersListContainer>
            </TabPane>
            <TabPane tab="Documents Upload" key="doc">
              <DocumentsUploadContainer></DocumentsUploadContainer>
            </TabPane>
            <TabPane tab="SMS Templates" key="smsTemplate">
              <SMSTemplatesContainer></SMSTemplatesContainer>
            </TabPane>
            <TabPane tab="SMS Logs" key="smsLogs">
              <SendSMSContainer></SendSMSContainer>
            </TabPane>
            {this.props.role === ADMIN ? (
              <TabPane tab="Clean Up" key="cleanUp">
                <CleanUp {...this.props}></CleanUp>
              </TabPane>
            ) : (
              ""
            )}
          </Tabs>
        </Card>
        {this.props.appConfig.successMessage && this.showNotifications()}
      </div>
    );
  }
}

export default Configure;
