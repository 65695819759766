import { axiosInstance } from "../../Interceptor";
import {
  GET_STATUS_SUMMARY,
  SEARCH_APPLICATIONS,
  CHANGE_STATUS,
  GET_COMMENTS,
  GET_STATUS_SUMMARY_BY_SCHOOL,
  SAVE_DRAFT_FILES,
  APP_HISTORY,
  GET_TASKS,
  CHANGE_TASK_STATUS,
  EXAM_CENTER_DETAILS,
  SAVE_EXAM_ROOM,
  APPLICATION_BULK_APPRV,
  DEL_DRAFT,
  EXCEL_EXPORT,
  START_SEATING,
  EXAM_DOCS,
  UPLOAD_MARKS,
  MARKS_CONFIG,
  SAVE_EXAM_CENTER,
} from "../utils/consts";
import { UPLOAD_ACTN_TYPE, GET_APPLICATION } from "../../student/util/const";

export const getStatusSummary = (year) => {
  return {
    type: GET_STATUS_SUMMARY,
    payload: axiosInstance.get(`/workflow/summary/${year}`),
  };
};

export const getStatusSummaryBySchool = (schoolId, year) => {
  return {
    type: GET_STATUS_SUMMARY_BY_SCHOOL,
    payload: axiosInstance.get(`/workflow/summary/school/${schoolId}/${year}`),
  };
};

export const searchApplications = (searchForm) => {
  return {
    type: SEARCH_APPLICATIONS,
    payload: axiosInstance.post(`/application/search`, searchForm),
  };
};

export const getShortListedApplications = (searchForm) => {
  const oldDistId = searchForm.oldDistId ? searchForm.oldDistId : 0;
  return {
    type: SEARCH_APPLICATIONS,
    payload: axiosInstance.get(`/application/shortListed/${oldDistId}`),
  };
};

export const exportApplications = (searchForm) => {
  return {
    type: EXCEL_EXPORT,
    payload: axiosInstance
      .post(`/export/applications`, searchForm, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        // window.open(url, '_blank');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "Applications";
        a.click();
        window.URL.revokeObjectURL(url);
      }),
  };
};

export const exportShortListedApplications = (searchForm) => {
  const oldDistId = searchForm.oldDistId ? searchForm.oldDistId : 0;
  return {
    type: EXCEL_EXPORT,
    payload: axiosInstance
      .get(`/export/shortListed/${oldDistId}`, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        // window.open(url, '_blank');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "ShortListed_Applications";
        a.click();
        window.URL.revokeObjectURL(url);
      }),
  };
};

export const exportHallTickets = () => {
  return {
    type: EXCEL_EXPORT,
    payload: axiosInstance
      .get(`/export/halltickets`, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        // window.open(url, '_blank');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = "Full_Seating_Order";
        a.click();
        window.URL.revokeObjectURL(url);
      }),
  };
};

export const changeStatus = (reasonView) => {
  return {
    type: CHANGE_STATUS,
    payload: axiosInstance.post(`/application/changeStatus`, reasonView),
  };
};

export const applicationBulkApprove = (applicationIds) => {
  return {
    type: APPLICATION_BULK_APPRV,
    payload: axiosInstance.post(`/application/approve`, applicationIds),
  };
};

export const applicationBulkReject = (applicationIds) => {
  return {
    type: APPLICATION_BULK_APPRV,
    payload: axiosInstance.post(`/application/reject`, applicationIds),
  };
};

export const getComments = (entityId, entityType) => {
  return {
    type: GET_COMMENTS,
    payload: axiosInstance.get(`/comment/${entityType}/${entityId}`),
  };
};

export const draftStudentDoc = (applicationId, file, type) => {
  const url = `/upload/draft`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("applicationId", applicationId);
  formData.append("type", type);
  return {
    type: UPLOAD_ACTN_TYPE[type],
    payload: axiosInstance.post(url, formData),
  };
};

export const saveDraftFiles = (applicationId, uploadCatList) => {
  return {
    type: SAVE_DRAFT_FILES,
    payload: axiosInstance.post(
      `/upload/draft/${applicationId}`,
      uploadCatList
    ),
  };
};

export const getAppHistory = (applicationId) => {
  return {
    type: APP_HISTORY,
    payload: axiosInstance.get(`/history/application/${applicationId}`),
  };
};

export const deleteDrafts = (applicationId) => {
  return {
    type: DEL_DRAFT,
    payload: axiosInstance.delete(`/upload/draft/${applicationId}`),
  };
};

export const getTaskList = (status) => {
  return {
    type: GET_TASKS,
    payload: axiosInstance.get(`/workflow/addschool/status/${status}`),
  };
};

export const getExamCenterDetails = (status) => {
  return {
    type: EXAM_CENTER_DETAILS,
    payload: axiosInstance.get(`/appconfig/examCenters`),
  };
};

export const changeTaskStatus = (workflow) => {
  return {
    type: CHANGE_TASK_STATUS,
    payload: axiosInstance.post(`/workflow/addschool/changestatus`, workflow),
  };
};

export const saveExamRooms = (examRoom) => {
  return {
    type: SAVE_EXAM_ROOM,
    payload: axiosInstance.post(`/appconfig/examRoom`, examRoom),
  };
};

export const saveExamCenter = (examCenter) => {
  return {
    type: SAVE_EXAM_CENTER,
    payload: axiosInstance.post(`/appconfig/examCenters`, examCenter),
  };
};

export const deleteExamCenter = (sourceId, destId) => {
  return {
    type: SAVE_EXAM_CENTER,
    payload: axiosInstance.delete(`/appconfig/examCenters/${sourceId}/${destId}`),
  };
};

export const downloadExamDocs = (oldDistId, distName) => {
  return {
    type: EXAM_DOCS,
    payload: axiosInstance
      .get(`/appconfig/examDocs/${oldDistId}`, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        let fileName = "Exam_Documents";
        if(distName) {
          fileName = distName + '_' + fileName;
        }
        // window.open(url, '_blank');
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }),
  };
};

export const getApplicationById = (applicationId) => {
  const url = `/application/${applicationId}`;
  return {
    type: GET_APPLICATION,
    payload: axiosInstance.get(url),
  };
};

export const processSeatingManagement = () => {
  const url = `/application/startSeating`;
  return {
    type: START_SEATING,
    payload: axiosInstance.get(url),
  };
};

export const getMarksConfig = () => {
  const url = `/appconfig/marksConfig`;
  return {
    type: MARKS_CONFIG,
    payload: axiosInstance.get(url),
  };
};

export const uploadMCTMarksSheet = (formData) => {
  const url = `/application/mct`;
  return {
    type: UPLOAD_MARKS,
    payload: axiosInstance.post(url, formData, { responseType: "blob" })
    .then((response) => {
      const url = window.URL.createObjectURL(response);
      // window.open(url, '_blank');
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "Result";
      a.click();
      window.URL.revokeObjectURL(url);
    }),
  };
};

export const uploadSSCMarksSheet = (formData) => {
  const url = `/application/ssc`;
  return {
    type: UPLOAD_MARKS,
    payload: axiosInstance.post(url, formData, { responseType: "blob" })
    .then((response) => {
      const url = window.URL.createObjectURL(response);
      // window.open(url, '_blank');
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "Result";
      a.click();
      window.URL.revokeObjectURL(url);
    }),
  };
};
