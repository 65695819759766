import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchMasterTableData } from "../../mastertables/actions/masterTables";
import {
  Card,
  Form,
  Button,
  Select,
  Row,
  Col,
  Switch,
  Spin,
  Table,
  notification,
} from "antd";
import { translate } from "../../base/actions/translate";
import { REQ_TYPES } from "../utils/const";
import {
  APRV,
  CHANGE_TASK_STATUS,
  CLOS,
  COMP,
  DEN,
  MED_REQ_APPRV,
  MON_REQ_APPRV,
  PEND,
  TASK_STATUSES,
  WEL_REQ_APPRV,
} from "../../common/utils/const";
import { getStatusString } from "../../common/utils/util";
import { sortNames } from "../../sampoorna-siksha/utils/util";
import moment from "moment";
import { searchBulkRequests } from "../actions/bulkRequests";
import { ADMIN } from "../../base/utils/roles";
import { RESET_DATA, RESET_MESSAGES } from "../../base/utils/const";
import {
  changeStatusInBulk,
  getApprovalLevels,
} from "../../common/actions/TaskActions";

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 12 },
  //   },
};

const TASK_LIST_COLS = [
  {
    title: "Task ID", //#638
    dataIndex: "taskId",
    key: "taskId",
    render: (text, record) => record.taskId,
    sorter: {
      compare: (a, b) => {
          return Number(a.taskId) - Number(b.taskId);
        },
    },
  },
  {
    title: "Student Name",
    dataIndex: "studentName",
    key: "studentName",
    render: (text, record) => record.firstName + " " + record.lastName,
    sorter: {
      compare: (a, b) =>
        sortNames(
          a.firstName + " " + a.lastName,
          b.firstName + " " + b.lastName
        ),
    },
  },
  {
    title: "Course",
    dataIndex: "course",
    key: "course",
    render: (text, record) => record.courses,
    sorter: {
      compare: (a, b) => sortNames(a.courses, b.courses),
    },
  },
  {
    title: "College",
    dataIndex: "college",
    key: "college",
    render: (text, record) => record.colleges,
    sorter: {
      compare: (a, b) => sortNames(a.colleges, b.colleges),
    },
  },
  {
    title: "Hostel",
    dataIndex: "hostel",
    key: "hostel",
    render: (text, record) => record.hostels,
    sorter: {
      compare: (a, b) => sortNames(a.hostels, b.hostels),
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, record) => record.amount,
    sorter: {
      compare: (a, b) => {
        if (a.amount && b.amount) {
          return Number(a.amount) - Number(b.amount);
        } else if (!a.amount) {
          return -1;
        } else if (!b.amount) {
          return 1;
        } else {
          return 0;
        }
      },
    },
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (text, record) => record.category,
    sorter: {
      compare: (a, b) => sortNames(a.category, b.category),
    },
  },
  {
    title: "Sub Category",
    dataIndex: "subCategory",
    key: "subCategory",
    render: (text, record) => (record.subCategory ? record.subCategory : "N/A"),
    sorter: {
      compare: (a, b) => sortNames(a.subCategory, b.subCategory),
    },
  },
];

const BulkRequestActions = (props) => {
  const [eventTypes, setEventTypes] = useState([]);
  const [requestType, setRequestType] = useState("");
  const [reqCat, setReqCat] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [selRequests, setSelRequests] = useState([]);
  const [statuses, setStatuses] = useState(TASK_STATUSES);
  const [approvalLevels, setApprovalLevels] = useState(
    props.tasks.approvalLevels
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.role) {
      setEventTypes(REQ_TYPES[props.role]);
    }
    props.getReqCats();
    props.resetData();
    props.getApprovalLevels();
  }, []);

  useEffect(() => {
    if (props.tasks.approvalLevels.length > 0) {
      setApprovalLevels(props.tasks.approvalLevels);
    }
  }, [props.tasks.approvalLevels]);

  useEffect(() => {
    if (props.role) {
      setEventTypes(REQ_TYPES[props.role]);
    }
  }, [props.role]);

  const onChange = (reqType) => {
    setRequestType(reqType);
    if (props.masterTable.reqCat.length > 0) {
      const cat = props.masterTable.reqCat.filter((c) => c.type === reqType);
      setReqCat(cat);
      form.setFieldsValue({
        category: "",
        subCategory: "",
      });
    }
  };

  const onCatChanged = (cat) => {
    setSubCatList(
      props.masterTable.reqCat?.find((c) => c.requestCategoryId === cat)
        .requestSubCategories
    );
    form.setFieldsValue({
      subCategory: "",
    });
  };

  const onSelectChange = (selectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelRequests(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selRequests,
    onChange: onSelectChange,
  };

  const getStatusContent = (apl, currentLevel, status, taskType) => {
    const html = [];
    let totalLevels = 0;
    for (const al of apl) {
      if (al.taskType === taskType) {
        totalLevels = al.levels;
      }
    }
    for (let i = 1; i <= totalLevels; i++) {
      if (i < currentLevel || (i < currentLevel && status === APRV)) {
        html.push(<span className="dot aprv"></span>);
      } else if (
        i === currentLevel &&
        status === COMP &&
        ![
          MED_REQ_APPRV.value,
          WEL_REQ_APPRV.value,
          MON_REQ_APPRV.value,
        ].includes(taskType)
      ) {
        html.push(<span className="dot aprv"></span>);
      } else if (i === currentLevel && status === CLOS) {
        html.push(<span className="dot aprv"></span>);
      } else if (i === currentLevel && status === DEN) {
        html.push(<span className="dot den"></span>);
      } else {
        html.push(<span className="dot"></span>);
      }
    }
    return <div>{html}</div>;
  };

  const searchTasks = (formVlues) => {
    props.searchBulkRequests(formVlues);
    setSelRequests([]);
  };

  const showSuccessNotifications = () => {
    let message = "";
    if (props.tasks.successMessage) {
      message += props.tasks.successMessage;
    }

    if (message) {
      notification.success({
        message: "Success",
        description: message,
      });
      props.resetMessage();
    }
  };

  const changeBulkStatus = (status) => {
    if (selRequests.length === 0) {
      notification.error({
        message: "Error",
        description: "Select atleast one record.",
      });
      return;
    }
    console.log("------bulk action------", status);
    props.changeTaskStatusInBulk(
      {
        taskIds: selRequests,
        status: status,
      },
      form.getFieldsValue()
    );
    setSelRequests([]);
  };

  if (TASK_LIST_COLS.length < 8 && approvalLevels.length > 0) {
    TASK_LIST_COLS.push({
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        getStatusContent(
          approvalLevels,
          record.assignedLevel,
          record.taskStatus,
          record.entityType + 1
        ),
      sorter: {
        compare: (a, b) => {
          if (a.assignedLevel !== b.assignedLevel) {
            return a.assignedLevel - b.assignedLevel;
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status !== b.status
          ) {
            if (a.status === APRV || a.status === DEN) {
              return 1;
            } else if (b.status === APRV || b.status === DEN) {
              return -1;
            }
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status === b.status
          ) {
            return 0;
          } else {
            return 0;
          }
        },
      },
    });
  }

  return (
    <React.Fragment>
      <Card title="Requests">
        <div>
          <Form
            onFinish={searchTasks}
            form={form}
            initialValues={{ assignedLevel: true }}
          >
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={6}>
                <Form.Item
                  {...formItemLayout}
                  name="entityType"
                  label="Task Category"
                >
                  <Select onChange={(e) => onChange(e)}>
                    {eventTypes.map((type) => (
                      <Select.Option key={type.key} value={type.key}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6} xs={24}>
                <Form.Item {...formItemLayout} name="category" label="Category">
                  <Select
                    className="full-width"
                    onSelect={(e) => onCatChanged(e)}
                  >
                    {reqCat.map((typ) => (
                      <Select.Option
                        key={typ.requestCategoryId}
                        value={typ.requestCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={6} xs={24}>
                <Form.Item
                  {...formItemLayout}
                  name="subCategory"
                  label="Sub-Category"
                >
                  <Select className="full-width">
                    {subCatList.map((typ, i) => (
                      <Select.Option
                        key={typ.requestSubCategoryId}
                        value={typ.requestSubCategoryId}
                      >
                        {typ.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  {...formItemLayout}
                  name="taskStatus"
                  label="Task Status"
                >
                  <Select>
                    {statuses.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {translate(type.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  {...formItemLayout}
                  label="Assigned"
                  name="assignedLevel"
                >
                  <Switch
                    className="toggle-show-hide"
                    checkedChildren="Me"
                    unCheckedChildren="All"
                    defaultChecked
                    // onChange={(e) => onChange(e)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={18}>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  {selRequests.length > 0 && (
                    <Button
                      type="primary"
                      className="modal-form-submit-button"
                      onClick={(e) => changeBulkStatus(APRV)}
                    >
                      Approve
                    </Button>
                  )}
                  {selRequests.length > 0 && (
                    <Button
                      className="modal-form-submit-button"
                      onClick={(e) => changeBulkStatus(DEN)}
                    >
                      Deny
                    </Button>
                  )}

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="modal-form-submit-button"
                  >
                    Search
                  </Button>
                  <Button
                    className="modal-form-submit-button"
                    onClick={e => form.resetFields()}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div>
          <Spin
            spinning={props.bulk.loading || props.tasks.loading}
            delay={500}
          >
            {props.tasks.approvalLevels.length > 0 && (
              <div style={{ overflowY: "auto" }}>
                {form.getFieldValue("taskStatus") === PEND &&
                ADMIN === props.role ? (
                  <Table
                    className="row-selection"
                    columns={TASK_LIST_COLS}
                    rowSelection={rowSelection}
                    rowKey={(record) => record.taskId}
                    dataSource={props.bulk.taskEntities}
                    pagination={{ showSizeChanger: true }}
                  />
                ) : (
                  <Table
                    className="row-selection"
                    columns={TASK_LIST_COLS}
                    rowKey={(record) => record.taskId}
                    dataSource={props.bulk.taskEntities}
                    pagination={{ showSizeChanger: true }}
                  />
                )}
              </div>
            )}
          </Spin>
        </div>
      </Card>
      {showSuccessNotifications()}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getApprovalLevels() {
      dispatch(getApprovalLevels());
    },
    getReqCats() {
      dispatch(fetchMasterTableData("reqCat"));
    },
    searchBulkRequests(form) {
      dispatch(searchBulkRequests(form));
    },
    changeTaskStatusInBulk(taskStatusForm, searchForm) {
      dispatch(changeStatusInBulk(taskStatusForm)).then((resp) => {
        if (resp.action.type === `${CHANGE_TASK_STATUS}_FULFILLED`) {
          this.searchBulkRequests(searchForm);
        }
      });
    },
    resetData() {
      dispatch({ type: RESET_DATA });
    },
    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
    BulkRequestsReducer,
    TaskReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
    bulk: BulkRequestsReducer,
    tasks: TaskReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulkRequestActions)
);
