import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RESET_MESSAGES } from "../../base/utils/const";
import { TOGGLE_ADD_MODAL, WEL_REQ } from "../../mastertables/utils/const";
import {
  deleteWelfareRequest,
  getWelfareRequests,
  saveWelfareRequest,
} from "../actions/studentList";
import moment from "moment";
import {
  sortNames,
} from "../../sampoorna-siksha/utils/util";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { APRV, DEN, PEND, TASK_TYPES, WEL_EVENT, WEL_REQ_APPRV } from "../../common/utils/const";
import { formItemLayout } from "../../sampoorna-siksha/utils/consts";
import { getApprovalLevels, getTaskComments } from "../../common/actions/TaskActions";
import TaskComments from "../../common/components/detailTemplates/TaskComments";
import {
  fetchRequestCategory,
  fetchRequestSubCategory,
} from "../../mastertables/actions/masterTables";
import { canEditRequest, getStatusContent, toCurrency } from "../../common/utils/util";
import { SET_CUR_REQ } from "../utils/const";
import { ACCEX, MSSSTUD } from "../../base/utils/roles";
import { getCurrentUserRole } from "../../service/SessionStorageService";

const dateFormat = "DD/MM/YYYY";
const EDIT_STUD_WEL = "EDIT_STUD_WEL";
const STUD_WEL_COLS = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record) =>
      record.createdAt ? moment(record.createdAt).format(dateFormat) : "",
    sorter: {
      compare: (a, b) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  },
  {
    title: "Type",
    dataIndex: "category",
    key: "category",
    render: (text, record) => record.requestCategory.name,
    sorter: {
      compare: (a, b) =>
        sortNames(a.requestCategory.name, b.requestCategory.name),
    },
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
    render: (text, record) => record.requestSubCategory.name,
    sorter: {
      compare: (a, b) =>
        sortNames(a.requestSubCategory.name, b.requestSubCategory.name),
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (text, record) => record.quantity,
    sorter: {
      compare: (a, b) => a.quantity - b.quantity,
    },
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    render: (text, record) => record.details,
    sorter: {
      compare: (a, b) => sortNames(a.details, b.details),
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text, record) => toCurrency(record.amount),
    sorter: {
      compare: (a, b) => sortNames(a.amount, b.amount),
    },
  },
];

const WelfareRequestForm = (props) => {
  const [formUpdated, setFormUpdated] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.manage.currentRequest?.category && props.masterTable.reqCat.length > 0) {
      onCatChanged(props.manage.currentRequest.category, props.manage.currentRequest.item);
    }
  }, [props.manage.currentRequest, props.masterTable.reqCat]);

  const onFinish = (form) => {
    console.log("-------", form);
    const studentProfileId = props.manage.currentProfile.studentProfileId;
    form.studentProfileId = studentProfileId;

    props.saveWelfareRequest({
      request: form,
      studentProfileId: studentProfileId,
      comment: form.comment,
    });
  };

  const onCancel = () => {
    form.resetFields();
    props.toogleAddModal("");
  };

  const onValueChange = () => {
    setFormUpdated(true);
  };

  const onCatChanged = (cat, item) => {
    setItemsList(
      props.masterTable.reqCat?.find((c) => c.requestCategoryId === cat)
        .requestSubCategories
    );
    form.setFieldsValue({
      item: item,
    });
  };

  return (
    <React.Fragment>
      <Modal
        visible={props.manage.showModal === EDIT_STUD_WEL}
        footer={null}
        onCancel={() => onCancel()}
        width="80%"
      >
        <div>
          <Spin tip="Loading..." spinning={props.masterTable.loading}>
            <Card
              title={
                props.manage.currentRequest.welfareRequestID
                  ? "Edit Welfare Request"
                  : "Add Welfare Request"
              }
            >
              <div>
                <Form
                  initialValues={props.manage.currentRequest}
                  onFinish={onFinish}
                  form={form}
                  onValuesChange={(e) => onValueChange(e)}
                >
                  <Form.Item
                    {...formItemLayout}
                    name="welfareRequestID"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="studentProfileId"
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={[16, 8]}>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="category"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select Category",
                          },
                        ]}
                      >
                        <Select
                          className="full-width"
                          onSelect={(e) => onCatChanged(e, "")}
                        >
                          {props.masterTable.reqCat.map((typ) => (
                            <Select.Option
                              key={typ.requestCategoryId}
                              value={typ.requestCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="item"
                        label="Item"
                        rules={[
                          {
                            required: true,
                            message: "Please select Item",
                          },
                        ]}
                      >
                        <Select className="full-width">
                          {itemsList.map((typ) => (
                            <Select.Option
                              key={typ.requestSubCategoryId}
                              value={typ.requestSubCategoryId}
                            >
                              {typ.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="quantity"
                        label="Quantity"
                        rules={[
                          {
                            required: true,
                            pattern: /^[1-9][0-9]*$/,
                            message: "Please enter a valid Quantity",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="details"
                        label="Details"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Details",
                          },
                        ]}
                      >
                        <Input.TextArea maxLength="1000" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        {...formItemLayout}
                        name="amount"
                        label="Amount"
                        rules={[
                          {
                            pattern: /^[1-9][0-9]*$/,
                            message: "Please enter a valid Amount",
                          },
                        ]}
                      >
                        <Input maxLength="10"/>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      {props.manage.currentRequest.welfareRequestID && (
                        <Form.Item
                          {...formItemLayout}
                          name="comment"
                          label="Comments"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please add Comments related to request update.",
                            },
                          ]}
                        >
                          <Input.TextArea maxLength="1000" />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button mg-l"
                      disabled={props.manage.loading}
                    >
                      Submit
                    </Button>
                    <Button
                      className="login-form-button mg-l"
                      onClick={() => onCancel()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </Spin>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const WelfareRequestComments = (props) => {
  return (
    <div>
      <Spin spinning={props.commentsLoading}>
        <TaskComments comments={props.comments} />
      </Spin>
    </div>
  );
};

const WelfareRequests = (props) => {
  // const [currentRecord, setCurrentRecord] = useState({});
  const [approvalLevels, setApprovalLevels] = useState(props.tasks.approvalLevels);

  useEffect(() => {
    if(props.tasks.approvalLevels.length === 0) {
      props.getApprovalLevels();
    }
    props.getReqCats(WEL_REQ);
    return () => {
      props.setCurrentRequest({});
    }
  }, []);

  useEffect(() => {
    if(props.manage.currentProfile.studentProfileId) {
      props.getWelfareRequests(props.manage.currentProfile.studentProfileId);
    }
  }, [props.manage.currentProfile.studentProfileId]);

  useEffect(() => {
    if (props.tasks.approvalLevels.length > 0) {
      setApprovalLevels(props.tasks.approvalLevels);
    }
  }, [props.tasks.approvalLevels]);

  const hasAccess = (currentLevel) => {
    if(!currentLevel) {
      if(props.role === MSSSTUD) {
        return true;
      }
      if (props.role && props.role !== ACCEX) {
        const taskTypes = TASK_TYPES[props.role];
        if (taskTypes) {
          return taskTypes.indexOf(WEL_REQ_APPRV) > -1;
        }
      }
      return false;
    }
    if(props.role === MSSSTUD) {
      return canEditRequest(currentLevel - 1, props.role, WEL_REQ_APPRV.value);
    }
    return canEditRequest(currentLevel, props.role, WEL_REQ_APPRV.value);
  };


  const addRecord = () => {
    props.setCurrentRequest({});
    props.toogleAddModal(EDIT_STUD_WEL);
  };

  const editRecord = (record, e) => {
    e.preventDefault();
    record.requestDate = moment(record.requestDate);
    props.setCurrentRequest(record);
    props.toogleAddModal(EDIT_STUD_WEL);
  };

  const deleteRecord = (record, e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Do you want to delete this request?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        props.deleteWelfareRequest(
          record.welfareRequestID,
          props.manage.currentProfile.studentProfileId
        );
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  if (STUD_WEL_COLS.length < 7 && approvalLevels.length > 0) {
    STUD_WEL_COLS.push({
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        getStatusContent(
          approvalLevels,
          record.assignedLevel,
          record.status,
          WEL_REQ_APPRV.value
        ),
      sorter: {
        compare: (a, b) => {
          if (a.assignedLevel !== b.assignedLevel) {
            return a.assignedLevel - b.assignedLevel;
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status !== b.status
          ) {
            if (a.status === APRV || a.status === DEN) {
              return 1;
            } else if (b.status === APRV || b.status === DEN) {
              return -1;
            }
          } else if (
            a.assignedLevel === b.assignedLevel &&
            a.status === b.status
          ) {
            return 0;
          } else {
            return 0;
          }
        },
      },
    });
    STUD_WEL_COLS.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Space size="middle">
            {hasAccess(record.assignedLevel) && (
              <a href="/" onClick={(e) => editRecord(record, e)}>
                <EditOutlined />
              </a>
            )}
            {hasAccess(record.assignedLevel) && (
              <a href="/" onClick={(e) => deleteRecord(record, e)}>
                <DeleteOutlined />
              </a>
            )}
          </Space>
        </>
      ),
    });
  }
  return (
    <React.Fragment>
      <Card>
        <h2>
          Welfare Requests
          <Button onClick={addRecord} type="primary" style={{ float: "right" }} disabled={!hasAccess()}>
            Add
          </Button>
        </h2>
        <Spin spinning={props.manage.loading} delay={500}>
          <div className="overflow-table">
          {approvalLevels.length > 0 && (
            <Table
              columns={STUD_WEL_COLS}
              dataSource={props.manage.currentProfile.welfareRequests}
              rowKey={(record) => record.welfareRequestID}
              pagination={{ showSizeChanger: true }}
              expandable={{
                expandedRowRender: (record) => (
                  <WelfareRequestComments
                    request={record}
                    comments={record.comments}
                    commentsLoading={props.tasks.loading}
                  />
                ),
                // rowExpandable: record => true,
                onExpand: (expanded, record) => {
                  if (expanded) {
                    props.getTaskComments(record.welfareRequestID, WEL_EVENT);
                  }
                },
              }}
            />
          )}
          </div>
        </Spin>
      </Card>
      {props.manage.showModal === EDIT_STUD_WEL && (
        <WelfareRequestForm {...props} />
      )}
      {props.onClose && (
        <div style={{ textAlign: "right", marginTop: "10px" }}>
          <Button onClick={(e) => props.onClose(e)} style={{ marginRight: 8 }}>
            Close
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // offlineSearch(searchText, profiles) {
    //   if (searchText && searchText.length > 1) {
    //     const result = profiles.filter((profile) => {
    //       return studProfPredicate(profile, searchText);
    //     });
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: result,
    //     });
    //   } else if (!searchText) {
    //     dispatch({
    //       type: SET_FILTERED_PROFILES,
    //       payload: profiles,
    //     });
    //   }
    // },

    getApprovalLevels() {
      dispatch(getApprovalLevels());
    },

    getWelfareRequests(id) {
      dispatch(getWelfareRequests(id));
    },

    getTaskComments(entityId, entityType) {
      dispatch(getTaskComments(entityId, entityType));
    },

    getReqCats(id) {
      dispatch(fetchRequestCategory(id));
    },

    getReqSubCats(id) {
      dispatch(fetchRequestSubCategory(id));
    },

    saveWelfareRequest(form) {
      dispatch(saveWelfareRequest(form)).then((res) => {
        this.getWelfareRequests(form.studentProfileId);
      });
    },

    deleteWelfareRequest(welId, profileId) {
      dispatch(deleteWelfareRequest(welId)).then((res) => {
        this.getWelfareRequests(profileId);
      });
    },

    toogleAddModal(modal) {
      dispatch({
        type: TOGGLE_ADD_MODAL,
        payload: modal,
      });
    },

    setCurrentRequest(data) {
      dispatch({
        type: SET_CUR_REQ,
        payload: data,
      });
    },

    resetMessage() {
      dispatch({
        type: RESET_MESSAGES,
      });
    },
  };
};

const mapStateToProps = (state) => {
  const {
    AuthReducer,
    AdminReducer,
    ManagementReducer,
    MasterTablesReducer,
    AppConfigReducer,
    ManagementUploadReducer,
    TaskReducer,
  } = state;
  return {
    profile: AuthReducer.profile,
    lang: AuthReducer.lang,
    role: AuthReducer.role,
    admin: AdminReducer,
    manage: ManagementReducer,
    masterTable: MasterTablesReducer,
    appConfig: AppConfigReducer,
    mgUploads: ManagementUploadReducer,
    comments: TaskReducer.taskComments,
    tasks: TaskReducer
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WelfareRequests)
);
