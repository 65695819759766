import {
  ACTV,
  COMPL,
  DENIED,
  DROP,
  ENROLL,
  HIGH_EDU,
  JOB_OFR,
  JOB_SEARCH,
  JOIN,
  PEND,
  RJOIN,
} from "./const";
import moment from "moment";
import { DEN, APRV } from "../../common/utils/const";

export const generateStudentId = (studProfile) => {
  let appId = studProfile.studentId.toString();
  while (appId.length < 3) {
    appId = "0" + appId;
  }

  return (
    (studProfile.program === 1 ? "MSS" : "MNP") + studProfile.batch + appId
  );
};

export const getStatusString = (code) => {
  switch (code) {
    case JOIN:
      return "Active";
    case ENROLL:
      return "Enrolled";
    case DROP:
      return "Dropped Out";
    case COMPL:
      return "Completed";
    case RJOIN:
      return "Rejoined";
    default:
      return "Active";
  }
};

export const getFutureStatusString = (code) => {
  switch (code) {
    case JOB_OFR:
      return "Job Offered";
    case HIGH_EDU:
      return "Higher Education";
    case JOB_SEARCH:
      return "Job Search";
    default:
      return "";
  }
};

export const getMarksIdByAttemptAndSub = (marksList, attempt, subId) => {
  if (!attempt || !marksList || marksList.length === 0) {
    return null;
  }
  return marksList.filter(
    (m) => m.attempt === attempt && m.curSubId === subId
  )[0]?.studentMarksId;
};

export const getMarksByAttemptAndSub = (marksList, attempt, subId) => {
  return marksList.filter(
    (m) => m.attempt === attempt && m.curSubId === subId 
  )[0]?.marks;
};

export const getGradeByAttemptAndSub = (marksList, attempt, subId) => {
  return marksList.filter(
    (m) => m.attempt === attempt && m.curSubId === subId
  )[0]?.grade;
};

export const getRankByAttempt = (marksList, attempt) => {
  return marksList.filter((m) => m.attempt === attempt)[0]?.info;
};

export const getResultByAttemptAndSub = (marksList, attempt, subId) => {
  return marksList.filter(
    (m) => m.attempt === attempt && m.curSubId === subId
  )[0]?.result;
};

export const getApprvResultByAttemptAndSub = (marksList, attempt, subId) => {
  return marksList.filter(
    (m) => m.attempt === attempt && m.curSubId === subId && m.status === APRV
  )[0]?.result;
};

export const getResultClassByAttemptAndSub = (marksList, attempt, subId) => {
  switch (getApprvResultByAttemptAndSub(marksList, attempt, subId)) {
    case 0:
      return "failed";
    default:
      return "";
  }
};

export const getFinalMarksBySub = (marksList, subId) => {
    return marksList
    .filter((m) => m.curSubId === subId)
    .sort((a, b) => b.marks - a.marks)[0]?.marks;
};

export const getGradePoint = (grade, gradeSystem) => {
  if (grade && gradeSystem) {
    for (const g of gradeSystem) {
      if (g.grade === grade) {
        return g.point;
      }
    }
  }
  return 0;
};

export const getFinalGradeBySub = (marksList, subId, gradeSystem) => {
  return marksList
    .filter((m) => m.curSubId === subId && m.status === APRV)
    .sort(
      (a, b) =>
        getGradePoint(b.grade, gradeSystem) -
        getGradePoint(a.grade, gradeSystem)
    )[0]?.grade;
};

export const calculateTotalMarksByAttempt = (
  marksList,
  subjectList,
  attempt
) => {
  if (!attempt || !marksList || marksList.length === 0) {
    return "-";
  }
  let total = 0;
  for (const cs of subjectList) {
    total += getMarksByAttemptAndSub(marksList, attempt, cs.curSubId) || 0;
  }
  return total;
};

export const calculateTotalMaxMarks = (marksList, subjectList) => {
  if (!subjectList || subjectList.length === 0) {
    return "-";
  }
  let total = 0;
 
  for (const cs of subjectList) {
    if(marksList.find((a) => a.curSubId === cs.curSubId && a.status === APRV))
    {
      total += cs.maxMarks;
    }
  }
  
  return total;
};

export const calculateTotalFinalMarks = (marksList, subjectList) => {
  if (!marksList || marksList.length === 0) {
    return "-";
  }
  let total = 0;
  for (const cs of subjectList) {
    total += getFinalApprovedMarksBySub(marksList, cs.curSubId);
  }
  return isNaN(total) ? '-' : total;
};

export const calculateTotalApprovedMakrs = (academics) => {
  let total = 0;
  const year = moment(academics.startYear).get("year");
  for (const sems of academics.semisters) {
    console.log("Curent course : ", academics);
    const courseCur = academics.course.curriculum
      ?.filter((cur) => cur.effectiveYear <= year)
      .sort((a, b) => b.effectiveYear - a.effectiveYear);
    console.log("Curent course cur : ", courseCur);
    const courseSubj = courseCur[0]?.curriculumSubjects?.filter(
      (cs) => cs.semId === sems.courseSemId
    );
    console.log("Curent course cur sub : ", courseSubj);
    if (courseSubj && courseSubj.length > 0) {
      if (sems.studentMarks && sems.studentMarks.length > 0) {
        total += calculateTotalApprovedFinalMarks(sems.studentMarks, courseSubj);
      }
    }
  }
  return total;
}

export const calculateTotalPercentage = (academics) => {
  let total = 0;
  let totalMaxMarks = 0;
  const year = moment(academics.startYear).get("year");
  for (const sems of academics.semisters) {
    console.log("Curent course : ", academics);
    const courseCur = academics.course.curriculum
      ?.filter((cur) => cur.effectiveYear <= year)
      .sort((a, b) => b.effectiveYear - a.effectiveYear);
    console.log("Curent course cur : ", courseCur);
    const courseSubj = courseCur[0]?.curriculumSubjects?.filter(
      (cs) => cs.semId === sems.courseSemId
    );
    console.log("Curent course cur sub : ", courseSubj);
    if (courseSubj && courseSubj.length > 0) {
      if (sems.studentMarks && sems.studentMarks.length > 0) {
        const t = calculateTotalMaxMarks(sems.studentMarks,courseSubj);
        if(!isNaN(t)) {
          totalMaxMarks += t;
        }
        const f = calculateTotalApprovedFinalMarks(sems.studentMarks, courseSubj);
        if(!isNaN(f)) {
          total += f;
        }
      }
    }
  }
  const percent = total === 0 ? 0 : (total / totalMaxMarks) * 100;
  return percent.toFixed(2);
};

export const calculateTotalApprovedFinalMarks = (marksList, subjectList) => {
  if (!marksList || marksList.length === 0) {
    return 0;
  }
  let total = 0;
  for (const cs of subjectList) {
    const t = getFinalApprovedMarksBySub(marksList, cs.curSubId);
    if(!isNaN(t)) {
      total += t;
    }
  }
  return total;
};

export const getFinalApprovedMarksBySub = (marksList, subId) => {
  return marksList
  .filter((m) => m.curSubId === subId && m.status === APRV)
  .sort((a, b) => b.marks - a.marks)[0]?.marks;
};

export const calculateTotalCredits = (academics) => {
  let total = 0;
  const year = moment(academics.startYear).get("year");
  for (const sems of academics.semisters) {
    console.log("Curent course : ", academics);
    const courseCur = academics.course.curriculum
      ?.filter((cur) => cur.effectiveYear <= year)
      .sort((a, b) => b.effectiveYear - a.effectiveYear);
    console.log("Curent course cur : ", courseCur);
    const courseSubj = courseCur[0]?.curriculumSubjects?.filter(
      (cs) => cs.semId === sems.courseSemId
    );
    console.log("Curent course cur sub : ", courseSubj);
    if (courseSubj && courseSubj.length > 0) {
      if (sems.studentMarks && sems.studentMarks.length > 0) {
        total += calculateTotalFinalMarks(sems.studentMarks, courseSubj);
      }
    }
  }
  return total;
};

export const getStatusClassByAttemptAndSub = (
  marksList,
  attempt,
  courseSubj
) => {
  if (!attempt || !marksList || marksList.length === 0) {
    return "";
  }
  let status = ACTV;
  if (courseSubj) {
    for (const cs of courseSubj) {
      if (
        marksList.filter(
          (m) => m.attempt === attempt && m.curSubId === cs.curSubId
        )[0]?.status === PEND
      ) {
        status = PEND;
      } else if (
        marksList.filter(
          (m) => m.attempt === attempt && m.curSubId === cs.curSubId
        )[0]?.status === DEN
      ) {
        status = DEN;
      }
    }
  }
  return getClassByStatus(status);
};

export const getClassByStatus = (status) => {
  switch (status) {
    case ACTV:
      return "";
    case PEND:
      return "pending";
    case DENIED:
      return "denied";
    default:
      return "";
  }
};

// Credit system

export const calculateTotalMaxCredits = (subjectList) => {
  if (!subjectList || subjectList.length === 0) {
    return "-";
  }
  let total = 0;
  let totalCredits = 0;
  for (const cs of subjectList) {
    total += cs.credits * cs.credits;
    totalCredits += cs.credits;
  }
  return (total / totalCredits).toFixed(2);
};

export const studAcctsPredicate = (userDetails, searchText) => {
  searchText = searchText.toUpperCase();
  let text = "";
  text += userDetails.user.username + " ";
  text += userDetails.firstName + " ";
  text += userDetails.lastName + " ";
  text += userDetails.mobileNumber + " ";
  text += userDetails.email;

  return text.toUpperCase().indexOf(searchText) > -1;
};

export const studProfPredicate = (profile, searchText) => {
  searchText = searchText.toUpperCase();
  let text = "";
  text += generateStudentId(profile) + " ";
  text += profile.firstName + " ";
  text += profile.lastName + " ";
  text += profile.batch + " ";
  text += profile.courses.join(", ");
  text += profile.instituteNames.join(", ");
  text += profile.hostelNames.join(", ");
  text += getStatusString(profile.status);

  return text.toUpperCase().indexOf(searchText) > -1;
};

// Grading system
export const getFinalGradePointBySub = (marksList, subId, gradeSystem) => {
  return getGradePoint(
    getFinalGradeBySub(marksList, subId, gradeSystem),
    gradeSystem
  );
};
export const calculateTotalFinalGradePoints = (
  marksList,
  subjectList,
  gradeSystem
) => {
  if (!marksList || marksList.length === 0) {
    return "-";
  }
  if (subjectList.length === 0) {
    return "-";
  }
  let total = 0;
  let totalMaxCredits = 0;
  for (const cs of subjectList) {
    total +=
      getFinalGradePointBySub(marksList, cs.curSubId, gradeSystem) * cs.credits;
    totalMaxCredits += cs.credits;
  }
  return total > 0 ? (total / totalMaxCredits).toFixed(2) : "-";
};

export const calculateTotalGradePointsByAttempt = (
  marksList,
  subjectList,
  attempt,
  gradeSystem
) => {
  if (!attempt || !marksList || marksList.length === 0) {
    return "-";
  }
  let total = 0;
  let totalMaxCredits = 0;
  for (const cs of subjectList) {
    total +=
      getGradePoint(
        getGradeByAttemptAndSub(marksList, attempt, cs.curSubId),
        gradeSystem
      ) * cs.credits;
    totalMaxCredits += cs.credits;
  }
  return total > 0 ? (total / totalMaxCredits).toFixed(2) : "-";
};

export const calculateCGPA = (academics) => {
  let total = 0;
  let totalMaxCredits = 0;
  const year = moment(academics.startYear).get("year");
  for (const sems of academics.semisters) {
    const courseCur = academics.course.curriculum
      ?.filter((cur) => cur.effectiveYear <= year)
      .sort((a, b) => b.effectiveYear - a.effectiveYear);

    let gradeSystem = [];
    if (
      courseCur[0] &&
      courseCur[0].gradeSystem &&
      courseCur[0].gradeSystem.gradePoints
    ) {
      gradeSystem = courseCur[0].gradeSystem.gradePoints;
    }

    const courseSubj = courseCur[0]?.curriculumSubjects?.filter(
      (cs) => cs.semId === sems.courseSemId
    );

    if (
      courseSubj &&
      courseSubj.length > 0 &&
      sems.studentMarks &&
      sems.studentMarks.length > 0
    ) {
      for (const cs of courseSubj) {
        total +=
        getFinalApprovedGradePointBySub(sems.studentMarks, cs.curSubId, gradeSystem) *
          cs.credits;
        totalMaxCredits += cs.credits;
      }
    }
  }
  return total > 0 ? (total / totalMaxCredits).toFixed(2) : "-";
};

export const getFinalApprovedGradePointBySub = (marksList, subId, gradeSystem) => {
  return getGradePoint(
    getFinalApprovedGradeBySub(marksList, subId, gradeSystem),
    gradeSystem
  );
};

export const getFinalApprovedGradeBySub = (marksList, subId, gradeSystem) => {
  return marksList
    .filter((m) => m.curSubId === subId && m.status === APRV)
    .sort(
      (a, b) =>
        getGradePoint(b.grade, gradeSystem) -
        getGradePoint(a.grade, gradeSystem)
    )[0]?.grade;
};

export const filterUploadsByEntityIdAndType = (uploads, entityIds, type) => {
  if(!uploads || uploads.length === 0 ) {
    return [];
  }
  return uploads.filter(u => entityIds.includes(u.entityId) && u.type === type);
}

export const getCountOfUploadsByEntityIdAndType = (uploads, entityIds, type) => {

  return filterUploadsByEntityIdAndType(uploads, entityIds, type).length > 0;
}